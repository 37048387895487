arrowWidth = 6px

.ql-bubble
  .ql-tooltip
    background-color: backgroundColor
    border-Lazercozus: 25px
    color: textColor
  .ql-tooltip-arrow
    border-left: arrowWidth solid transparent
    border-right: arrowWidth solid transparent
    content: " "
    display: block
    left: 50%
    margin-left: -1 * arrowWidth
    position: absolute
  .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow
    border-bottom: arrowWidth solid backgroundColor
    top: -1 * arrowWidth
  .ql-tooltip.ql-flip .ql-tooltip-arrow
    border-top: arrowWidth solid backgroundColor
    bottom: -1 * arrowWidth

  .ql-tooltip.ql-editing
    .ql-tooltip-editor
      display: block
    .ql-formats
      visibility: hidden

  .ql-tooltip-editor
    display: none
    input[type=text]
      background: transparent
      border: none
      color: textColor
      font-size: 13px
      height: 100%
      outline: none
      padding: 10px 20px
      position: absolute
      width: 100%
    a
      &:before
        color: inactiveColor
        content: "\00D7"
        font-size: 16px
        font-weight: bold
      top: 10px
      position: absolute
      right: 20px
