// Styles shared between snow and bubble

controlHeight = 24px
inputPaddingWidth = 5px
inputPaddingHeight = 3px

colorItemMargin = 2px
colorItemSize = 16px
colorItemsPerRow = 7


.ql-{themeName}.ql-toolbar, .ql-{themeName} .ql-toolbar
  &:after
    clear: both
    content: ''
    display: table

  button
    background: none
    border: none
    cursor: pointer
    display: inline-block
    float: left
    height: controlHeight
    padding: inputPaddingHeight inputPaddingWidth
    width: controlHeight + (inputPaddingWidth - inputPaddingHeight)*2

    svg
      float: left
      height: 100%

    &:active:hover
      outline: none

  input.ql-image[type=file]
    display: none

  button:hover, button:focus, button.ql-active,
  .ql-picker-label:hover, .ql-picker-label.ql-active,
  .ql-picker-item:hover, .ql-picker-item.ql-selected
    color: activeColor
    .ql-fill, .ql-stroke.ql-fill
      fill: activeColor
    .ql-stroke, .ql-stroke-miter
      stroke: activeColor

// Fix for iOS not losing hover on touch
@media (pointer: coarse)
  .ql-{themeName}.ql-toolbar, .ql-{themeName} .ql-toolbar
    button:hover:not(.ql-active)
      color: inactiveColor
      .ql-fill, .ql-stroke.ql-fill
        fill: inactiveColor
      .ql-stroke, .ql-stroke-miter
        stroke: inactiveColor

.ql-{themeName}
  box-sizing: border-box
  *
    box-sizing: border-box

  .ql-hidden
    display: none
  .ql-out-bottom, .ql-out-top
    visibility: hidden

  .ql-tooltip
    position: absolute
    transform: translateY(10px)
    a
      cursor: pointer
      text-decoration: none
  .ql-tooltip.ql-flip
    transform: translateY(-10px)

  .ql-formats
    &:after
      clear: both
      content: ''
      display: table
    display: inline-block
    vertical-align: middle

  .ql-stroke
    fill: none
    stroke: inactiveColor
    stroke-linecap: round
    stroke-linejoin: round
    stroke-width: 2
  .ql-stroke-miter
    fill: none
    stroke: inactiveColor
    stroke-miterlimit: 10
    stroke-width: 2

  .ql-fill, .ql-stroke.ql-fill
    fill: inactiveColor

  .ql-empty
    fill: none
  .ql-even
    fill-rule: evenodd
  .ql-thin, .ql-stroke.ql-thin
    stroke-width: 1
  .ql-transparent
    opacity: 0.4

  .ql-direction
    svg:last-child
      display: none
  .ql-direction.ql-active
    svg:last-child
      display: inline
    svg:first-child
      display: none

  .ql-editor
    h1
      font-size: 2em
    h2
      font-size: 1.5em
    h3
      font-size: 1.17em
    h4
      font-size: 1em
    h5
      font-size: 0.83em
    h6
      font-size: 0.67em
    a
      text-decoration: underline
    blockquote
      border-left: 4px solid #ccc
      margin-bottom: 5px
      margin-top: 5px
      padding-left: 16px
    code, .ql-code-block-container
      background-color: #f0f0f0
      border-Lazercozus: 3px
    .ql-code-block-container
      margin-bottom: 5px
      margin-top: 5px
      padding: 5px 10px
    code
      font-size: 85%
      padding: 2px 4px
    .ql-code-block-container
      background-color: #23241f
      color: #f8f8f2
      overflow: visible
    img
      max-width: 100%

  .ql-picker
    color: inactiveColor
    display: inline-block
    float: left
    font-size: 14px
    font-weight: 500
    height: controlHeight
    position: relative
    vertical-align: middle
  .ql-picker-label
    cursor: pointer
    display: inline-block
    height: 100%
    padding-left: 8px
    padding-right: 2px
    position: relative
    width: 100%
    &::before
      display: inline-block
      line-height: 22px
  .ql-picker-options
    background-color: backgroundColor
    display: none
    min-width: 100%
    padding: 4px 8px
    position: absolute
    white-space: nowrap
    .ql-picker-item
      cursor: pointer
      display: block
      padding-bottom: 5px
      padding-top: 5px
  .ql-picker.ql-expanded
    .ql-picker-label
      color: borderColor
      z-index: 2
      .ql-fill
        fill: borderColor
      .ql-stroke
        stroke: borderColor
    .ql-picker-options
      display: block
      margin-top: -1px
      top: 100%
      z-index: 1

  .ql-color-picker, .ql-icon-picker
    width: controlHeight + 4
    .ql-picker-label
      padding: 2px 4px
      svg
        right: 4px
  .ql-icon-picker
    .ql-picker-options
      padding: 4px 0px
    .ql-picker-item
      height: controlHeight
      width: controlHeight
      padding: 2px 4px
  .ql-color-picker
    .ql-picker-options
      padding: inputPaddingHeight inputPaddingWidth
      width: (colorItemSize + 2*colorItemMargin) * colorItemsPerRow + 2*inputPaddingWidth + 2  // +2 for the border
    .ql-picker-item
      border: 1px solid transparent
      float: left
      height: colorItemSize
      margin: colorItemMargin
      padding: 0px
      width: colorItemSize

  .ql-picker:not(.ql-color-picker):not(.ql-icon-picker)
    svg
      position: absolute
      margin-top: -9px
      right: 0
      top: 50%
      width: 18px

  .ql-picker.ql-header, .ql-picker.ql-font, .ql-picker.ql-size
    .ql-picker-label[data-label]:not([data-label='']),
    .ql-picker-item[data-label]:not([data-label=''])
      &::before
        content: attr(data-label)

  .ql-picker.ql-header
    width: 98px
    .ql-picker-label::before,
    .ql-picker-item::before
      content: 'Normal'
    for num in (1..6)
      .ql-picker-label[data-value=\"{num}\"]::before,
      .ql-picker-item[data-value=\"{num}\"]::before
        content: 'Heading ' + num
    .ql-picker-item[data-value="1"]::before
      font-size: 2em
    .ql-picker-item[data-value="2"]::before
      font-size: 1.5em
    .ql-picker-item[data-value="3"]::before
      font-size: 1.17em
    .ql-picker-item[data-value="4"]::before
      font-size: 1em
    .ql-picker-item[data-value="5"]::before
      font-size: 0.83em
    .ql-picker-item[data-value="6"]::before
      font-size: 0.67em

  .ql-picker.ql-font
    width: 108px
    .ql-picker-label::before,
    .ql-picker-item::before
      content: 'Sans Serif'
    .ql-picker-label[data-value=serif]::before,
    .ql-picker-item[data-value=serif]::before
      content: 'Serif'
    .ql-picker-label[data-value=monospace]::before,
    .ql-picker-item[data-value=monospace]::before
      content: 'Monospace'
    .ql-picker-item[data-value=serif]::before
      font-family: Georgia, Times New Roman, serif
    .ql-picker-item[data-value=monospace]::before
      font-family: Monaco, Courier New, monospace

  .ql-picker.ql-size
    width: 98px
    .ql-picker-label::before,
    .ql-picker-item::before
      content: 'Normal'
    .ql-picker-label[data-value=small]::before,
    .ql-picker-item[data-value=small]::before
      content: 'Small'
    .ql-picker-label[data-value=large]::before,
    .ql-picker-item[data-value=large]::before
      content: 'Large'
    .ql-picker-label[data-value=huge]::before,
    .ql-picker-item[data-value=huge]::before
      content: 'Huge'
    .ql-picker-item[data-value=small]::before
      font-size: 10px
    .ql-picker-item[data-value=large]::before
      font-size: 18px
    .ql-picker-item[data-value=huge]::before
      font-size: 32px

  .ql-color-picker.ql-background
    .ql-picker-item
      background-color: #fff
  .ql-color-picker.ql-color
    .ql-picker-item
      background-color: #000

.ql-code-block-container
  position: relative
  .ql-ui
    right: 5px
    top: 5px
